import React from 'react'
import { PageProps } from 'gatsby'

import { GlobeAltIcon, LightningBoltIcon } from '@heroicons/react/outline'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ContentDetails from '../components/ContentDetails'

const mainFeatureItems = [
  {
    id: 1,
    name: 'The future of Trading Communications',
    description:
      'With BT Unified Trading, BT has set the benchmark for the next decade by brining to market a powerful, cost- effective, software-based cloud-compatible trader voice, multi-media and collaboration solution. By consolidating your infrastructure you can reduce your costs and increase flexibility by using solutions as a service through scalable, secure and compliant private trusted clouds.',
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: 'Interact, trade securely, comply with regulation',
    description:
      'Multi-channel communication: so your trading teams can communicate with clients, counterparties and colleagues using a blend of speech, video and messaging from the broadest range of fixed and mobile devices and applications Flexibility: flexible deployment options so you can integrate your enterprise systems with your trading floor IT and combine cloud with on-premise solutions in the best way for your Community collaboration: across Radianz Cloud Compliance : helping you respond to legal holds and regulatory investigations more effectively with proactive surveillance.',
    icon: LightningBoltIcon,
  },
  {
    id: 3,
    name: 'Move to a unified communications solution',
    description:
      'Free your trading teams, counterparties, and clients to collaborate more effectively, while also giving you valuable client insight.',
    icon: LightningBoltIcon,
  },
  {
    id: 4,
    name: 'Communication',
    description:
      `BT Unified Trading voice service
      BT Unified Trading session manager`,
    icon: LightningBoltIcon,
  },
  {
    id: 5,
    name: 'Collaboration',
    description:
      `BT unified Trading collaboration server`,
    icon: LightningBoltIcon,
  },
  {
    id: 6,
    name: 'Compliance',
    description:
      `BT Unified Trading recorder `,
    icon: LightningBoltIcon,
  }
]

const ProjectServices = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <ContentDetails 
          title="BT UNIFIED TRADING" 
          mainFeatures={mainFeatureItems} 
          footer="Please contact us for more information." />
      </main>
    
      <Footer />
    </div>
  )
}

export default ProjectServices